/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

/* Theme variables, mixins must be included before the libraries to allow overriding defaults */
@import "./theme/theme-variables";
@import "./theme/mixins/common";
@import "./theme/theme.scss";
@import "./theme/popup-style.scss";

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* All onboard form can use this class with an enclosing div */
.onboarding-form, .view-details-form {
  padding: 20px 24px;
  background-color: var(--white);
  border-radius: 15px;
  label .field-required {
    color: var(--font-var-12);
  }
}

@media screen and (max-width: 600px) {
  .onboarding-form, .view-details-form {
    margin-top: 15px !important;
  }
}
