.change-password-container, .lens-add-modal {
    .modal-content {
        background: #ffffff !important;
        border-radius: 25px !important;
    }
}
.lens-add-modal {
    .modal-content {
        width: 500px;
        overflow-x: auto;
    }
}
.common-alert-popup {
    .modal-dialog {
        justify-content: center;
    }
    .modal-content {
        width: 90%;
        border-radius: 15px !important;
    }
}
.question-suggestion-popup {
    .modal-content {
        width: auto;
        padding: 30px;
        border-radius: 15px !important;
    }
}

.common-confirmation-popup {
    .modal-content {
        width: 100%;
        border-radius: 15px !important;
    }
}