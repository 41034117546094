/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

// Refer doc for html code snippets - https://docs.google.com/document/d/1x4j8wK_DSHm4SFw1vTvQZbB0n1_jWxsvBrXNjN5OXzc/edit

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');

/* Font classes start */

/*
 * How to add a new heading class?
 * If the heading has same font size but different weight or line height from the below defined classes
 * create the class with a different type number.
 * For example consider a heading with 45px size, 800 weight, 68px line height.
 * Its class will added as .kl-h1-type-3 since it has same font size as .kl-h1-type-1 but different other properties.
 * Consider another example as heading with 12px size, 700 weight, 18px line height.
 * At the time this comment is added there is no heading class with font-size 12px, hence should create a new class as
 * .kl-h6-type-1.
 */

/* The heading number added is not based on the size (eg .kl-h1 is not h1 because its the highest of font size)
 * Managing it with size can be cumbersome since design can be changed in future and more headings can come.
 */
.kl-h1-type-1 {
    @include font(45px, 500, 68px, var(--black));
}

.kl-h1-type-2 {
    @include font(45px, 600, 68px, var(--white));
}

.kl-h2-type-1 {
    @include font(24px, 600, 36px, var(--black));
}

.kl-h3-type-1 {
    @include font(21px, 400, 32px, var(--black));
}

.kl-h3-type-2 {
    @include font(21px, 400, 32px, var(--primary));
}

.kl-h4-type-1 {
    @include font(18px, 500, 18px, var(--black));
}

.kl-h5-type-1 {
    @include font(14px, 600, 21px, var(--font-var-2));
}

.kl-h5-type-2 {
    @include font(14px, 500, 18px, var(--black));
}

.kl-h5-type-3 {
    @include font(14px, 400, 21px, var(--font-var-5));
}

.kl-h5-type-4 {
    @include font(14px, 500, 20px, var(--font-var-1));
}

.kl-h5-type-5 {
  @include font(16px, 500, 24px, var(--black));
}

.kl-h5-type-6 {
    @include font(16px, 500, 24px, var(--orange-var-1));
}

.kl-h5-type-7 {
    @include font(16px, 500, 24px, var(--white));
}

.kl-h6-type-1 {
    @include font(32px, 500, 48px, var(--black));
}

.kl-h7-type-1{
  @include font(20px, 500, 30px, var(--black));
}

/* Plain text classes */
/*
 * How to add new text classes ? - It should be added similar the heading classes
 * Please refer comments in the heading class section.
 */
.kl-text1-type-1 {
    @include font(20px, 500, 30px, var(--black));
}

.kl-text1-type-8 {
    @include font(20px, 500, 30px, var(--white));
}

.kl-text1-type-9 {
    @include font(24px, 400, 36px, var(--font-var-15));
}

.kl-text1-type-2 {
    @include font(20px, 500, 30px, var(--black));
}

.kl-text1-type-4 {
    @include font(20px, 500, 30px, var(--primary));
}

.kl-text1-type-3 {
    @include font(16px, 500, 30px, var(--primary));
}

.kl-text1-type-5 {
    @include font(20px, 400, 30px, var(--primary));
}

.kl-text1-type-6 {
    @include font(22px, 400, 30px, var(--white));
}

.kl-text1-type-7 {
    @include font(20px, 400, 33px, var(--white));
}

.kl-text2-type-1 {
    @include font(16px, 400, 24px, var(--black));
}

.kl-text2-type-2 {
    @include font(16px, 500, 24px, var(--white));
}

.kl-text2-type-3 {
    @include font(16px, 500, 24px, var(--black));
}

.kl-text2-type-4 {
    @include font(12px, 400, 18px, var(--gray-80));
}

.kl-text3-type-1 {
    @include font(14px, 400, 21px, var(--font-var-5));
}

.kl-text3-type-7 {
    @include font(14px, 500, 21px, var(--black));
}

.kl-text3-type-2 {
    @include font(14px, 500, 21px, var(--white));
}

.kl-text3-type-3 {
    @include font(14px, 400, 22px, var(--font-var-5));
}

.kl-text3-type-4 {
    @include font(14px, 500, 20px, var(--white));
}

.kl-text3-type-5 {
    @include font(14px, 500, 22px, var(--font-var-5));
}

.kl-text3-type-6 {
    @include font(14px, 400, 20px, var(--font-var-14));
}

.kl-text3-type-8 {
    @include font(14px, 500, 21px, var(--primary));
}

.kl-text4-type-1 {
    @include font(13px, 600, 20px, var(--primary));
}

.kl-text5-type-1 {
    @include font(12px, 500, 18px, var(--font-var-5));
}

.kl-text5-type2 {
    @include font(12px, 400, 18px, var(--font-var-1));
}

.kl-text5-type3 {
    @include font(12px, 400, 20px, var(--font-var-5));
}

.kl-text5-type4 {
    @include font(12px, 400, 18px, var(--font-table-content));
}

.kl-text5-type-5 {
    @include font(12px, 500, 18px, var(--font-download-text));
}

.kl-text5-type-6 {
    @include font(12px, 400, 18px, var(--font-var-16));
}

.kl-text5-type-7 {
    @include font(12px, 500, 18px, var(--font-var-18));
}
.kl-text5-type-8 {
    @include font(14px, 500, 20px, var(--font-var-19));
}
.kl-text5-type-9 {
    @include font(12px, 400, 18px, var(--font-var-25));
}
.kl-text5-type-10 {
    @include font(16px, 500, 24px, var(--font-var-25));
}
.kl-text5-type-11 {
    @include font(12px, 400, 18px, var(--black));
}
.kl-text5-type-12 {
    @include font(12px, 600, 18px, var(--black));
}
.kl-text5-type-13 {
  @include font(12px, 400, 18px, var(--font-var-27));
}
.kl-text6-type1 {
    @include font(24px, 400, 36px, var(--black));
}

.kl-text6-type2 {
    @include font(24px, 500, 36px, var(--white));
}

.kl-text6-type3 {
    @include font(18px, 400, 27px, var(--black));
}
.kl-text6-type-4 {
    @include font(12px, 500, 18px, var(--font-var-23));
}
.kl-text6-type-6 {
    @include font(16px, 600, 24px, var(--font-var-26));
}
.kl-text6-type-5 {
    @include font(12px, 500, 18px, var(--font-var-24));
}
.error-text {
    @include font(12px, 400, 18px, var(--red-var-1));
}
.info-text {
  @include font(12px, 400, 18px, var(--red-var-1));
}
.warning-text-type-1 {
  @include font(10px, 400, 18px, var(--red-var-1));
}

.kl-table-empty-text {
    @include font(14px, 400, 18px, var(--no-data-found-label));
}


.kl-status {
    // use this for style status texts.
    @include font(12px, 500, 18px, var(--font-var-5));

    &.status-pending {
        color: var(--font-var-8);
    }

    &.status-production {
        color: var(--font-var-9);
    }

    &.status-completed {
        color: var(--font-var-10);
    }
}

// div container for error validation error texts
.error-message-container-common {
    height: 18px;
    p {
        padding: 0;
        margin: 0;
        font-size: 13px;
        color: var(--input-error-message);
    }
}

.cursor-pointer {
    cursor: pointer;
}

/* Media Queries Start */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .kl-h1-type-1 {
        @include font(24px, 500, 30px, var(--black));
    }
    .kl-h3-type-1 {
        @include font(16px, 400, 32px, var(--black));
    }

    .kl-h3-type-2 {
        @include font(16px, 400, 32px, var(--primary));
    }
    .kl-h6-type-1 {
        @include font(20px, 500, 30px, var(--black));
    }
    .kl-text2-type-1 {
        @include font(14px, 400, 24px, var(--black));
    }
    .kl-h1-type-2 {
        @include font(1.8rem, 600, 4.25rem, var(--white));
    }
    .kl-text1-type-1 {
        @include font(1rem, 500, 30px, var(--black));
    }
    .kl-h5-type-6 {
        @include font(0.8rem, 500, 24px, var(--orange-var-1));
    }
    .kl-h5-type-7 {
        @include font(0.8rem, 500, 24px, var(--white));
    }
    .kl-text1-type-3 {
        @include font(0.8rem, 500, 30px, var(--primary));
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .kl-h1-type-1 {
        @include font(28px, 500, 40px, var(--black));
    }
    .kl-text6-type2 {
        @include font(18px, 500, 36px, var(--white));
    }
    .kl-text6-type3 {
        @include font(14px, 400, 27px, var(--black));
    }
    .kl-text1-type-6 {
        @include font(14px, 400, 30px, var(--white));
    }
    .kl-text1-type-7 {
        @include font(14px, 400, 30px, var(--white));
    }
    .kl-text1-type-9 {
        @include font(16px, 400, 36px, var(--font-var-15));
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
    .kl-h1-type-1 {
        @include font(28px, 500, 40px, var(--black));
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
/* Media Queries End */
/* Font classes end */

/*
* Color classes start
* Add font color classes here.
*/
.gray-80 {
    color: var(--gray-80);
}
/* Color classes end */

/* Background colors */
.kl-bg-primary {
    background-color: var(--primary);
}
.kl-bg-body {
    background-color: var(--bg-var-2);
}
.kl-bg-disabled {
  background-color: var(--bg-var-6);
}
/* Background colors end */

/* Input styles start */
.kl-input-text {
    border: 1px solid var(--border-var-2);
    background: var(--white);
    display: block !important;
    width: 100% !important;

    &:focus {
        box-shadow: none !important;
    }

    &:focus-visible {
        border: 1.5px solid !important;
        border-color: var(--primary) !important;
        outline-color: var(--primary) !important;
    }
}

.kl-input-text.input-text-type-1 {
    border-radius: 9px !important;
    padding: 19px 45px 17px 25px !important;
    &::placeholder {
        @include font(14px, 400, 21px, var(--font-var-5));
    }

    &::-webkit-input-placeholder {
        /* Edge */
        @include font(14px, 400, 21px, var(--font-var-5));
    }

    &:-ms-input-placeholder {
        /* Internet Explorer */
        @include font(14px, 400, 21px, var(--font-var-5));
    }
}

.kl-input-text.input-text-type-2 {
    border-radius: 6px;
    padding: 10px 14px;
    line-height: 18px;
    @include font(14px, 400, 18px, var(--font-var-5));
    &::placeholder {
        @include font(14px, 400, 18px, var(--font-var-5));
    }

    &::-webkit-input-placeholder {
        /* Edge */
        @include font(14px, 400, 21px, var(--font-var-5));
    }

    &:-ms-input-placeholder {
        /* Internet Explorer */
        @include font(14px, 400, 21px, var(--font-var-5));
    }
}

.kl-input-text.input-text-type-3 {
  border-radius: 4px !important;
  padding: 10px 14px;
  line-height: 18px;
  @include font(12px, 400, 18px, var(--font-var-5));
  &::placeholder {
      @include font(12px, 400, 18px, var(--font-var-5));
  }

  &::-webkit-input-placeholder {
      /* Edge */
      @include font(12px, 400, 18px, var(--font-var-5));
  }

  &:-ms-input-placeholder {
      /* Internet Explorer */
      @include font(12px, 400, 18px, var(--font-var-5));
  }
}

// invalid input fields border style
input.ng-invalid.ng-touched {
    border: var(--input-invalid-border);
}

// Use input-with-icon class in a div to display inputs with icon inside input.
div.input-with-icon-type-1 {
    position: relative;

    .input-icon {
        position: absolute;
    }

    .to-right {
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
    }

    .to-left {
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
}

// Ms edge password field default icon hide
input::-ms-reveal,
input::-ms-clear {
    display: none;
}

/** password criteria displaying popover and icon styles start **/
.kl-password-criteria-icon {
    font-size: 14px;
}
.kl-password-criteria-text {
    @include font(12px, 400, 18px, var(--black));
}
/** password criteria displaying popover and icon styles end **/

.kl-select-container {
    position: relative;
    width: fit-content;

    & i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        pointer-events: none;
        margin-left: -17px;
    }

    select.kl-select {
        @include font(12px, 400, 18px, var(--font-var-5));
        width: 100%;
        background-color: white;
        border: thin solid var(--border-var-2);
        border-radius: 6px;
        display: inline-block;
        line-height: 1.5em;
        padding: 10px 14px;
        padding-right: 30px;
        word-wrap: normal !important;
        white-space: normal;

        /* reset */
        margin: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        &:focus-visible {
            border: 1px solid var(--primary);
            outline-color: var(--primary);
        }
    }
}

.kl-input-search-bar {
    width: 200px;
    height: 35px;
    background: var(--bg-var-2);
    border-radius: 7px;
    padding-left: 33px;
    border: none;
    outline: none;
    color: var(--font-var-5);
}

@media (max-width: 768px) {
    .kl-input-search-bar {
        width: 133px;
    }
}

@media (min-width: 486px) {
}

// Use this class along with specific classes defined below to style checkbox and radio buttons
.kl-check-default {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    @include font(12px, 400, 18px, var(--font-var-5));
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    span {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: var(--white);
    }

    &:hover input ~ span {
        background-color: var(--bg-var-5);
    }

    span:after {
        content: '';
        position: absolute;
        display: none;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    input:checked ~ span:after {
        display: block;
    }
}

// Use this class with class - "kl-check-default" for input type radio
.kl-radio-container {
    span {
        border: 2px solid var(--border-var-4);
        border-radius: 10px;
    }

    input:checked ~ span {
        border: 2px solid var(--primary);
    }

    span:after {
        left: 3.05px;
        top: 3.05px;
        width: 9px;
        height: 9px;
        border-radius: 4.5px;
        background: var(--primary);
        border-width: 0 2px 2px 0;
    }
}

//  Use this class with class - "kl-check-default" for input type checkbox
.kl-checkbox-container {
    span {
        border: 1px solid var(--border-var-4);
        border-radius: 3px;
    }

    input:checked ~ span {
        background-color: var(--primary);
        border: 1px solid var(--primary);
    }

    span:after {
        left: 6px;
        top: 2px;
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 2px 2px 0;
    }
}

.custom-checkbox input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: 2px solid var(--primary);
    border-radius: 4px;
    width: 16px;
    height: 16px;
}

.custom-checkbox input[type="checkbox"]:checked {
    background-color: var(--primary);
}

.custom-checkbox input[type="checkbox"]:checked::before {
    content: "\2713";
    color: var(--white);
    display: block;
    text-align: center;
    line-height: 13px;
    font-size: 12px;
}

/* INput styles end */

/* Button styles start */
button.kl-btn-primary,
a.kl-btn-primary {
    // Use for standard buttons with plain text.
    @include font(16px, 500, 24px, var(--white));
    padding: 15px;
    text-align: center;
    background: var(--primary);
    border: none;
    border-radius: 10px;
    box-shadow: 0px 4px 19px var(--orange-var-3);

    &:hover {
        background: var(--primary);
        color: var(--white);
    }
    &:focus-visible {
        outline: none !important;
        border: none !important;
    }
}

button.kl-btn-outline-type-1 {
    // Use this for button with plane text and with no icon.
    @include font(16px, 500, 18px, var(--primary));
    background: none;
    border: 1.5px solid var(--primary);
    &:hover {
        color: var(--white) !important;
        background-color: var(--primary);
        border: var(--primary);
    }
}

button.kl-btn-primary-type-2 {
  @include font(16px, 500, 18px, var(--white));
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);  // Specifying the text color for the default state

  &:hover {
    background-color: var(--primary); // Slightly darken on hover
    color: var(--white);
    border-color:var(--primary);
  }

  &:focus,
  &:focus-visible {
    background-color: var(--primary);
    box-shadow: 0 0 0 0.2rem rgba(var(--primary), 0.5); // Adding a shadow with a bit of transparency
    border-color: var(--primary);
    outline: none;  // Removing the default browser outline
  }

  &:active {
    background-color: var(--primary) !important; // Darken more on active
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);  // Inset shadow for a pressed look
    border-color: var(--primary);
  }

  &:disabled {
    background-color: var(--primary); // Lighten for a "grayed out" look
    color: var(--primary);
    border-color:var(--primary);
    cursor: not-allowed; // Indicating that the button is not clickable
  }
}


button.kl-btn-with-icon-type-1,
a.kl-btn-with-icon-type-1 {
    // Use for buttons with icon and light primary color background.
    @include font(12px, 500, 18px, var(--primary));
    background: var(--orange-var-4);
    padding: 10px 13px;
    border: none;
    border-radius: 7px;

    i {
        font-size: 12px;
        color: var(--primary);
        margin-right: 10px;
    }

    &.kl-btn-outline {
        background: none;
        border: 1.5px solid var(--primary);
        @include flex('center', 'center');
        i {
            font-size: 18px;
            font-weight: 600;
        }
        &:hover {
            color: var(--white) !important;
            i {
                color: var(--white) !important;
            }
        }
    }
    &:hover {
        background: var(--orange-var-1);
        span {
            color: var(--white);
        }
        &:hover {
            color: var(--white);
        }
    }
}

button.kl-btn-with-icon-type-white,
a.kl-btn-with-icon-type-white {
    // Use for buttons with icon and light primary color background.
    @include font(12px, 500, 18px, rgba(0, 0, 0, 0.7));
    background: white;
    padding: 10px 13px;
    border: none;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    i {
        font-size: 12px;
        color: var(--primary);
        margin-right: 10px;
    }

    &.kl-btn-outline {
        background: none;
        border: 1.5px solid var(--primary);
        @include flex('center', 'center');
        i {
            font-size: 18px;
            font-weight: 600;
        }
        &:hover {
            color: var(--white) !important;
            i {
                color: var(--white) !important;
            }
        }
    }
    &:hover {
        background: var(--orange-var-1);
        span {
            color: var(--white);
        }
        &:hover {
            color: var(--white);
        }
    }
}

button.kl-period-btn {
    @include font(12px, 500, 18px, var(--primary));
    min-width: 140px;
    height: 38px;
    background: none;
    padding: 10px 13px;
    border: 1px black;
    border-radius: 7px;
    color: rgba(0, 0, 0, 0.7)
}


button.kl-btn-text-only,
a.kl-btn-text-only {
    @include font(16px, 500, 18px, var(--primary));
    background: none;
    border: none;
    text-decoration: underline;
}

button.kl-btn-text-only-type-2,
a.kl-btn-text-only-type-2 {
    @include font(12px, 500, normal, var(--primary));
    background: none;
    border: none;
}

/* Common style for button used in auth pages */
.auth-form-btn-container {
    button {
        height: 3.375rem;
        border-radius: 0.625rem;
        width: 50%;
    }
}

/* Onboard form add and cancel button styles start */
button.kl-btn-add-save-user, button.kl-btn-continue {
    @include font(12px, 500, 18px, var(--white));
    min-width: 140px;
    height: 38px;
    background: var(--primary);
    padding: 10px 13px;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 4px 19px var(--orange-var-3);
}
button.kl-save-draft-btn {
    @include font(12px, 500, 18px, var(--primary));
    min-width: 140px;
    height: 38px;
    background: var(--orange-var-3);
    padding: 10px 13px;
    border: none;
    border-radius: 7px;
}
button.kl-btn-cancel-action {
    @include font(12px, 500, 18px, var(--primary));
    min-width: 140px;
    height: 38px;
    background: var(--white);
    padding: 10px 13px;
    border: 1px solid var(--orange-var-4) !important;
    border-radius: 8px;

    &.kl-btn-outline {
        background: none;
        border: 1.5px solid var(--primary) !important;
        &:hover {
            color: var(--white) !important;
        }
    }

    &:hover {
        background: var(--orange-var-1);
        span {
            color: var(--white);
        }
    }
}

button.warning-btn-with-icon-type-1 {
    @include font(12px, 400, 18px, var(--white));
    background: var(--red-var-2);
    box-shadow: 0px 4px 19px rgba(229, 83, 83, 0.15);
    border-radius: 105px;
    border: none;

    img {
        margin-left: 8px;
        width: 12px !important;
        height: 10px !important;
    }
}

button.succuss-btm-with-icon-type-1 {
    @include font(12px, 400, 18px, var(--white));
    background: var(--green-var-3);
    box-shadow: 0px 4px 19px rgba(229, 83, 83, 0.15);
    border-radius: 105px;
    border: none;

    img {
        margin-left: 8px;
        width: 12px !important;
        height: 10px !important;
    }
}

button.kl-btn-cancel-order-btn {
    @include font(12px, 500, 18px, var(--white));
    min-width: 140px;
    height: 38px;
    background: rgba(229, 83, 83, 1);
    padding: 10px 13px;
    border: none;
    color:white;
    border-radius: 8px;
    // box-shadow: 0px 4px 19px var(--red-var-1);
}
/** Onboard form add and cancel button styles end **/

/* Media Queries Start */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    button.kl-btn-primary,
    a.kl-btn-primary {
        @include font(14px, 500, 16px, var(--white));
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/* Media Queries Start */
/* Button styles end */

/* Active link start */
.active-type-1 {
    // Use to highlight the selected link in the sidebar.
    background: var(--primary);
    border-radius: 10px;
    color: var(--white) !important;
}
.active-type-2 {
  background: var(--primary);
  background-color: var(--primary) !important;
  color: var(--white) !important;
}

/* Active link end */

/* Hover state start */
.hover-type-1 {
    // Use for sidebar link hover state.
    &:hover {
        background-color: var(--primary);
        border-radius: 10px;
        color: var(--white);
    }
}

.hover-type-2:not(.active-type-1) {
    // Use for sidebar inner link hover state.
    &:hover {
        background-color: var(--orange-var-4) !important;
        border-radius: 10px;
        color: var(--primary);
    }
}

.hover-type-3 {
    // Use for table row hove state.
    &:hover {
        background: var(--bg-var-5);
    }
}

.hover-type-4 {
    // Use for pagination button hover state.
    &:hover {
        background: var(--bg-var-1);
    }
}

@media (min-width: 768px) {
}

@media (min-width: 486px) {
}

/* Hover state end */

// Margin start.
.mb-10 {
    margin-bottom: 10px !important;
}
// Margin end.

// Bootstrap class overrides
input.form-control {
    &:focus {
        box-shadow: 0 0 0 0.25rem var(--orange-var-4) !important;
    }
}

/* Main table common styles start */
.kl-table-container {
    overflow-x: auto;
    overflow-y: auto;
    padding-top: 11px;
    border-radius: 15px;
}

.table-upper-container {
  min-height: 60px;
}

.kl-table-upper-container {
    height: 50px;
    align-items: center;
    border-radius: 10px;
    margin-top: 20px;
}

@media screen and (max-width: 786px) {
  .kl-table-upper-container {
    height: auto;
  }
}

/* Table and Pagination overrides */
// Overriding bootstrap class styles.
.pagination {
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 1rem;
    --bs-pagination-color: var(--bs-link-color);
    --bs-pagination-bg: var(--white) !important;
    --bs-pagination-border-width: 0px;
    --bs-pagination-border-color: none !important;
    --bs-pagination-border-radius: 0px;
    --bs-pagination-hover-color: var(--bs-link-hover-color);
    --bs-pagination-hover-bg: var(--bg-var-1) !important;
    --bs-pagination-hover-border-color: var(--bg-var-1);
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: var(--bg-var-1) !important;
    --bs-pagination-focus-box-shadow: 0 0 0 0 var(--primary) !important;
    --bs-pagination-active-color: var(--font-var-1);
    --bs-pagination-active-bg: var(--bg-var-1) !important;
    --bs-pagination-active-border-color: var(--bg-var-1) !important;
    --bs-pagination-disabled-color: var(--bg-var-1) !important;
    --bs-pagination-disabled-bg: var(--white) !important;

    margin: 0;
    max-width: 500px;
    overflow-x: auto;

    li {
        margin-right: 5px;
        &.active {
            border-radius: 6px;
            overflow: hidden;
        }

        .page-link {
            @include font(12px, 500, 18px, var(--font-var-1));

            &:hover {
                border-radius: 6px;
            }

            color: var(--font-var-1);
            .prev-link-custom {
                font-size: 18px;
            }
        }
    }
}

@media (max-width: 486px) {
    .pagination {
        max-width: 100%;
        margin-top: 20px;
    }
}

.table {
    thead {
        @include font(12px, 500, 18px, var(--font-var-5));
        border-bottom: 1px solid var(--border-var-6);
        tr:not(.pull-row-left) {
            th:first-child {
                padding-left: 32px;
            }
        }
        tr.pull-row-left {
          th:first-child{
            padding-left: 0;
          }
        }
    }

    tbody {
        @include font(12px, 400, 18px, var(--black));
        border-bottom: 1px solid var(--border-var-7);
        th {
            font-weight: 400 !important;
            padding-left: 25px;
        }
        tr:not(.pull-row-left) {
            td:first-child {
                padding-left: 32px;
            }
        }

        tr.pull-row-left {
          td:first-child{
            padding-left: 0;
          }
        }

        --bs-table-hover-bg: var(--bg-var-5) !important;
    }

    .table-hover > tbody > tr:hover > * {
        background-color: var(--bg-var-5) !important;
    }
}
/* Main table common styles end */

.btn-link, .ngb-dp-weekday{
  --bs-btn-color: var(--primary) !important;
  color: var(--primary) !important;
}
.no-edit {
    color: var(--black);
    background: var(--gray-disabled-variant);
    pointer-events: none;
}
.disabled-form-section {
    pointer-events: none;
}

.tooltip {
  min-width: 100px !important;
  width: auto !important;
}

.custom-scrollbar {
  // Firefox
  scrollbar-width: thin;
  scrollbar-color: var(--bg-var-7);

  // Webkit (Chrome, Safari)
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-light-shade);

    &:hover {
      background: var(--gray-light-shade);
    }
  }

  &::-webkit-scrollbar-track {
    background: var(--bg-var-2);
  }
}

.auto-width-md-up {
  width: 100% !important; /* Default to 100% width */

  @media (min-width: 768px) { /* Apply auto width from md and up */
    width: auto !important;
  }
}

.position-change{
    width: 100%;
    margin: 100px auto !important;
}
.detail-count-container {
    justify-content: center;
    @media only screen and (max-width: 768px) {
        .additional-details-label {
            margin-right: 0.5rem;
        }
    }
}

.kl-profile-image {
    font-size: 10px;
    border-radius: 50%;
    color: var(--user-profile-image-color);
    background: var(--user-profile-image-bg);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

/* Text area styles start */
.kl-textarea-container {
  position: relative;

  textarea.kl-textarea {
    font-family: "Poppins", sans-serif;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: var(--font-var-5);
    width: 100%;
    background-color: white;
    border: thin solid var(--border-var-2);
    border-radius: 6px;
    display: inline-block;
    line-height: 1.5em;
    padding: 10px 14px;
    padding-right: 30px; // Additional padding to the right
    word-wrap: normal !important;
    white-space: pre-wrap; // Allows line breaks and white space
    margin: 0;
    box-sizing: border-box;

    &:focus-visible {
      border: 1px solid var(--primary);
      outline-color: var(--primary);
    }

    &::placeholder {
      color: var(--font-var-5);
      opacity: 0.7;
    }
  }
}
/* Text area styles end */

form.field-disabled{
  input {
    pointer-events: none;
    background-color: var(--bg-var-2) !important;
  }
  select {
    pointer-events: none;
    background-color: var(--bg-var-2) !important;
  }
  ul{
    pointer-events: none;
  }
}

@media screen and (max-width: 786px) {
    button.kl-btn-cancel-action.kl-btn-outline, .kl-save-draft-btn, .kl-btn-add-save-user{
      margin: 0 !important;
      width: 100% !important;
      margin-bottom: 10px !important;
    }
}


.filter-button-container {
  .clear-filter {
    right: -2px;
    top: -2px;
    color: var(--red-var-1);
    font-size: 12px;
  }
}
