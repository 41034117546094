/*
 * Application global variables.
 */

// Required
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';

:root {
    // Useful colors.
    --black: #000;
    --blue-var-1: #d0e4f7; //1
    --blue-var-2: #90b8e3; //2
    --blue-var-3: #52aaeb; //3
    --blue-var-4: #4285f4; //4
    --blue-var-5: #006bde; //5
    --blue-var-6: #0163E0; //6
    --brown-var-1: #c87575;
    --citrine: #e4d0ff;
    --green-var-1: #c0dabf;
    --green-var-2: #85a983;
    --green-var-3: #65be3b;
    --orange-var-1: #db9600;
    --orange-var-2: #fd8539;
    --peach-skin: #e1bbbb;
    --red-var-1: #ff3154;
    --red-var-2: #E55353;
    --white: #fff;
    --gray-80: #808080;
    --gray-light-shade: #cfcccc;
    --gray-disabled-variant: #e3e3e3;
    // RGBA
    --orange-var-3: rgba(219, 150, 0, 0.3);
    --orange-var-4: rgba(219, 150, 0, 0.2);
    --orange-var-5: rgba(219, 150, 0, 0.15);

    // Theme variables.
    --primary: #db9600;

    // Font colors.
    --font-var-1: #1c1c1c;
    --font-var-2: #11142d;
    --font-var-3: #333333;
    --font-var-4: #535352;
    --font-var-5: #808080;
    --font-var-6: #919191;
    --font-var-7: #f7f7f7;
    --font-var-8: #fd8539;
    --font-var-9: #52aaeb;
    --font-var-10: #65be3b;
    --font-var-11: var(--primary);
    --font-var-12: #ff3154;
    --font-var-13: tint-color(var(--black), 40%);
    --font-var-14: rgba(0, 0, 0, 0.4);
    --font-var-15: #333131;
    --no-data-found-label: #9f9c9c;
    --font-table-content: #000000;
    --font-download-text: rgba(219, 150, 0, 1);
    --font-var-16: rgba(83, 83, 82, 1);
    --font-var-18: rgba(51, 51, 51, 1);
    --font-var-19: rgba(83, 83, 82, 1);
    --font-var-20: #DB9600;
    --font-var-21: #a80808;
    --font-var-22: #3379D3;
    --font-var-23: rgba(219, 150, 0, 1);
    --font-var-24: #E65353;
    --font-var-25: rgba(0, 0, 0, 1);
    --font-var-26: rgba(128, 128, 128, 1);
    --font-var-27: rgba(0, 0, 0, 0.70);
    --font-var-28: #c3c3c3;

    // Background colors.
    --bg-var-1: #ebeaea;
    --bg-var-2: #f4f4f4;
    --bg-var-3: var(--primary);
    --bg-var-4: tint-color(var(--primary), 15%);
    --bg-var-5: #f7f7f7;
    --bg-var-6: #d2d2d2;
    --bg-var-7: #e4d0ff;
    --bg-var-8: #85a983;
    --bg-var-9: #c0dabf;
    --bg-var-10: #c87575;
    --bg-var-11: #e1bbbb;
    --bg-var-12: #90b8e3;
    --bg-var-13: #d0e4f7;
    --bg-var-14: #84d061;
    --bg-var-15: #adadad;
    --bg-var-16: tint-color(var(--primary), 30%);
    --bg-var-17: tint-color(var(--primary), 20%);
    --bg-var-18: #FCFCFC;
    --bg-var-19: rgba(219, 150, 0, 0.25);
    --bg-treatment-form: rgba(0,0,0,0.85);
    --facd-image-gradient: rgba(4, 16, 39, 0.7) 100%;
    --facd-question-container-bg: rgba(255, 255, 255, 0.3);
    --bg-var-20: rgba(246, 177, 0, 0.3);
    --bg-var-21: #F5D2D2;
    --bg-var-22: rgba(217, 217, 217, 1);

    // Border colors.
    --border-var-1: var(--primary);
    --border-var-2: #adadad;
    --border-var-3: #4285f4;
    --border-var-4: #808080;
    --border-var-5: #006bde;
    --border-var-5: #006BDE;
    --border-var-6: #E4D0FF;
    --border-var-7: #F7F7F7;
    --border-var-8: rgba(0, 0, 0, 0.18);

    // common color auth module
    --auth-page-background-color: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);
    --signin-link: rgb(74, 147, 243);
    --input-label-color: #555;
    --input-border-color: #ccc;
    --input-invalid-border: 1px solid red;
    --input-error-message: red;
    --button-bg: #065492;
    --button-text-color: #fff;
    --checkbox-inside-color: rgb(74, 147, 243);
    --d1-red-value-round-box: #dd6767;

    // 404 page colors
    --heading-color-404: #00b5c3;
    --button-box-shadow-404: rgb(0 0 0 / 30%);

    // Loader
    --loader-bg: rgb(0 0 0 / 30%);

    // New for kalculus
    --input-invalid-border: 1px solid red;

    --profile-dropdown-shadow: rgba(0, 0, 0, 0.12);
    --profile-dropdown-item-bg: #cdc3c37a;

    --user-profile-image-color: rgba(1, 99, 224, 1);
    --user-profile-image-bg: rgb(174, 204, 244);

    // shadow colors
    --box-shadow-var-1: #888888;
}

// Sidebar variables
$main-content-width: calc(100% - 250px) !default;
$sidebar-mini-width: calc(100% - 6.4rem) !default;
