@mixin flex($justify, $align){
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin animate-width($from, $to){
  animation: animate-width .2s linear;
}

@mixin animate($animation,$duration,$method,$times){
  animation: $animation $duration $method $times;
}

@mixin keyframes($name){
  @keyframes #{$name}{
      @content;
  }
}

// Authentication pages button style common
@mixin auth-button-default($isActive: true) {
  @if $isActive {
      width: 70%;
      height: 40px;
      margin: auto;
      display: block;
      color: var(--button-text-color);
      background-color: var(--button-bg);
      border-color: var(--button-bg);
      border-radius: 4px;
      font-size: 14px;
      cursor: pointer;
  } @else {
      opacity: 0.5;
      cursor: auto;
  }
}
@mixin main-container-default {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
}

// Fonts related mixins.
@mixin font-family {
  font-family: 'Poppins', sans-serif;
}

@mixin font($size, $weight, $height, $color) {
  @include font-family();
  font-weight: $weight !important;
  font-size: $size !important;
  line-height: $height !important;
  color: $color;
}


// kalculus section
@mixin main-container-default-style {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
}
